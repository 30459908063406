import { JSX }	from "preact";

import * as money	from "@geotoura/shared/money";
import * as fbModel from "@geotoura/shared/fbModel";

import { useMessages }	from "@geotoura/fb/useMessages";
import * as actions		from "@geotoura/fb/actions";

export type BudgetProps	= Readonly<{
	screen:		fbModel.ScreenOfBudget,
	answer:		fbModel.AnswerOfBudget,
	traveldays:	number,
}>;

export const Budget = ({ screen, answer, traveldays }:BudgetProps):JSX.Element => {
	const msg	= useMessages();

	const setValue	= (percentVal:number):void =>
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ABudget>().atKey("budget").set(money.Percent.brand(percentVal))
		);

	const setSlider = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void =>
		setValue(parseInt(ev.currentTarget.value));

	// dayDefault in % + half of slider thumb width
	const markPosition = {
		left: "calc(" + fbModel.DayAverages.budgetEuroToPercent(screen.content.dayDefault, screen.content.dayAverages) + "% + 7px)",
	};

	const impressionSet	= fbModel.ImpressionList.forBudget(screen.content.impressionSets, answer.content.budget);

	return (
		<div class="Budget Screen">
			<div class="heading">{screen.content.title1}{traveldays}{screen.content.title2}</div>
			<div class="Budget-icon-wrapper flex-r">
				<button class="Budget-button" onClick={() => setValue(12)}><img class="Budget-icon" src="/img/fb/budget/Balken-01.svg" alt=""/></button>
				<button class="Budget-button" onClick={() => setValue(37)}><img class="Budget-icon" src="/img/fb/budget/Balken-02.svg" alt=""/></button>
				<button class="Budget-button" onClick={() => setValue(62)}><img class="Budget-icon" src="/img/fb/budget/Balken-03.svg" alt=""/></button>
				<button class="Budget-button" onClick={() => setValue(87)}><img class="Budget-icon" src="/img/fb/budget/Balken-04.svg" alt=""/></button>
			</div>
			<div class="Budget-slider-wrapper">
				<input type="range" class="Budget-slider Slider-slider" min={0} max={100} value={answer.content.budget} step="1" onChange={setSlider}/>
				<div class="Budget-slider-mark" style={markPosition}></div>
				<div class="Budget-slider-label-tick" style={{ left: 0 + "%" }}></div>
				<div class="Budget-slider-label-tick" style={{ left: 25 + "%" }}></div>
				<div class="Budget-slider-label-tick" style={{ left: 50 + "%" }}></div>
				<div class="Budget-slider-label-tick" style={{ left: 75 + "%" }}></div>
				<div class="Budget-slider-label-tick" style={{ left: 100 + "%" }}></div>
			</div>
			<div class="Budget-slider-labels">
				{
					screen.content.dayAverages.map(it =>
						<div class="Budget-slider-label">{
							msg.format.number.euroInteger(money.Euro.multiply(it, traveldays))
						}</div>
					)
				}
			</div>
			<div class="Budget-impressions-title heading">{screen.content.impressionTitle} </div>
			<div class="Budget-impressions">
				{
					impressionSet.map(it =>
						<div class="Budget-impression">
							<img src={it.image} class="Budget-impression-image"/>.
							<div class="Budget-impression-text">{it.text}</div>
						</div>
					)
				}
			</div>
			<div class="Budget-note">{screen.content.note}</div>
		</div>
	);
};
