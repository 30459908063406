import { JSX }		from "preact";
import { useState }	from "preact/hooks";

import { Arrays }	from "ts-base/arrays";

import * as fbModel		from "@geotoura/shared/fbModel";

import { useMessages }	from "@geotoura/fb/useMessages";
import * as actions		from "@geotoura/fb/actions";
import { Modal }		from "@geotoura/fb/components/Modal";
import { Checkbox }		from "@geotoura/fb/components/Checkbox";

export type SelectlistProps	= Readonly<{
	screen:	fbModel.ScreenOfSelectList,
	answer:	fbModel.AnswerOfSelectList,
}>;

type Child = {
	parentId:	fbModel.ListItemId,
	childId:	fbModel.SubListItemId,
};

export const Selectlist = ({ screen, answer }:SelectlistProps):JSX.Element => {
	const msg = useMessages();

	const [ modalOpen,	setOpen ]	= useState<number|null>(null);

	const selectRegion = (ids:Child, _index:number, checked:boolean):void => {
		if (!checked) {
			actions.doRefresh(
				actions.answerContent(screen.id).cast<fbModel.ASelectList>().atKey("selected").whereRo((it:fbModel.AListItem) => it.id === ids.parentId).mod(it => {
					const parent = screen.content.list.find(li => li.id === it.id);
					if (parent === undefined) throw new Error("should not happen");
					const sub = parent.children.find(li => li.id === ids.childId);
					if (sub === undefined) throw new Error("should not happen");
					return {
						...it,
						children: it.children.concat({ id: sub.id, valueLi: sub.valueLi, mailValue: sub.label }),
					};
				})
			);
		}
		else {
			// remove region
			actions.doRefresh(
				actions.answerContent(screen.id).cast<fbModel.ASelectList>().atKey("selected").whereRo((it:fbModel.AListItem) => it.id === ids.parentId).mod(it =>
					({
						...it,
						children:	it.children.filter(item => item.id !== ids.childId),
					})
				)
			);
		}
	};

	// , index:number, checked:boolean
	const toggleCheckbox = (listItemId:fbModel.ListItemId):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ASelectList>().atKey("selected").mod(it => {
				const foundItem:fbModel.AListItem|undefined = it.find((item) => item.id === listItemId);
				if (foundItem !== undefined) {
					return Arrays.removeWhere(it)((item:fbModel.AListItem) => item.id === listItemId);
				}
				else {
					const item = screen.content.list.find(it => it.id === listItemId);
					if (item === undefined) throw new Error ("should now happen");
					return it.concat({
						id:			item.id,
						valueLi:	item.valueLi,
						mailValue:	item.label,
						children:	[],
					});
				}
			})
		);
	};

	const closeModal = ():void => setOpen(null);

	const openModal = (index:number) => (ev:Event):void => {
		ev.preventDefault();
		setOpen(index);
	};

	return (
		<div class="Selectlist Screen">
			<div class="heading">{screen.content.title}</div>
			<ul>
				{
					screen.content.list.map((item, index) => {
						const selected = answer.content.selected.find(it => it.id === item.id);
						return (
							<li class="">
								<Checkbox
									index={index}
									action={toggleCheckbox}
									value={item.id}
									checked={selected !== undefined}
								>
									<div class="Selectlist-item">
										<div style={{ backgroundImage: `url("${item.icon}")` }} class="Selectlist-item-icon"></div>
										<div class="Selectlist-item-content">
											{
												(selected !== undefined && selected.children.length > 0) &&
												<button onClick={openModal(index)} class="Selectlist-edit-icon">
													<span class="fa-light fa-pencil"></span>
													<span class="sr-only">Edit</span>
												</button>
											}
											<div class={"Selectlist-item-content-text " + (selected !== undefined && selected.children.length > 0 ? "withChildren" : "")}>{item.label}</div>
											{
												(item.children.length > 0 && selected !== undefined && selected.children.length < 1) &&
												<button onClick={openModal(index)} class="Selectlist-edit-text">{msg.selectlist.selectRegion}</button>
											}
											{
												(selected !== undefined && selected.children.length > 0) &&
												<ul>
													{
														selected.children.map((child, index) =>
															<li class="Selectlist-sublist-item">
																{child.mailValue}
																{index < selected.children.length -1 ? "," : ""}
															</li>
														)
													}
												</ul>
											}
										</div>
									</div>
								</Checkbox>
								{
									item.children.length > 0 &&
									<Modal open={modalOpen === index} action={closeModal}>
										<div class="Selectlist-modal-title">
											<div style={{ backgroundImage: `url("${item.icon}")` }} class="Selectlist-modal-title-icon"></div>
											<div class="Selectlist-modal-title-text">{item.label}</div>
										</div>
										<div class="heading">{screen.content.modalTitle}</div>
										<ul>
											{
												item.children.map((child, childindex) => {
													const subSelected = selected !== undefined && selected.children.find(it => it.id === child.id);
													const ids:Child = {
														parentId:	item.id,
														childId:	child.id,
													};
													return (
														<li>
															<Checkbox
																index={childindex}
																action={selectRegion}
																value={ids}
																checked={subSelected !== undefined}
															>
																<div class="Selectlist-modal-item">{child.label}</div>
															</Checkbox>
														</li>
													);
												})
											}
										</ul>
									</Modal>
								}
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};
