import { Branded }		from "ts-base/branded";
import { Validated }	from "ts-base/validated";
import * as QS			from "ts-base/validation/query";
import * as JS			from "ts-base/validation/json";

import { Numeric }		from "@v3/data/numeric";
import {
	NaturalNumber,
}	from "@v3/data/primitive";

import {
	LanguageCode,
}	from "@geotoura/shared/i18n";
import {
	Euro,
	Percent,
}	from "@geotoura/shared/money";
import {
	RegionId,
	TerritoryId,
	ExampleRouteId,
}	from "@geotoura/shared/commonModel";

//-----------------------------------------------------------------------------

export type QuestionnaireId		= Numeric.Value<typeof QuestionnaireId>;
export const QuestionnaireId	= NaturalNumber.rebrand("QuestionnaireId");

export type ListItemId		= Branded<number, "ListItemId">;
export const ListItemId		= Branded.brand<ListItemId>;

export type SubListItemId	= Branded<number, "SubListItemId">;
export const SubListItemId	= Branded.brand<SubListItemId>;

export const QuestionnaireRequest	=
	QS.objectOf({
		lang:	QS.one(LanguageCode.query),
		id:		QS.one(QuestionnaireId.query),
	});
export type QuestionnaireRequest	= QS.Output<typeof QuestionnaireRequest>;

export type Questionnaire = Readonly<{
	id:				QuestionnaireId,
	screens:		ReadonlyArray<Screen>,
	description:	string|null,
	comment:		string|null,
}>;

//-----------------------------------------------------------------------------

export type ScreenId	= Branded<number, "ScreenId">;
export const ScreenId	= Branded.brand<ScreenId>;

export type QuestionnaireScreenId	= Branded<number, "QuestionnaireScreenId">;
export const QuestionnaireScreenId	= Branded.brand<QuestionnaireScreenId>;

//-----------------------------------------------------------------------------

export type Screen	=
	ScreenOfRadiostart	|
	ScreenOfSelectList	|
	ScreenOfDate		|
	ScreenOfFlight		|
	ScreenOfHotel		|
	ScreenOfCounterList	|
	ScreenOfSliderList	|
	ScreenOfBudget		|
	ScreenOfContact		|
	ScreenOfText		|
	ScreenOfDecision;

export const Screen	= {
	isOf:	<I extends ScreenKind>(stId:I) => (self:Screen):self is ScreenOf<I> => self.stId === stId,
};

export type ScreenKind	= Screen["stId"];
export type ScreenType	= Screen["content"];

export type ScreenKindOf<I extends Screen>	= I["stId"];
export type ScreenOf<I extends ScreenKind>	= Extract<Screen, { stId:I }>;

export type ScreenOfRadiostart	= BaseScreen & { stId: "RADIOSTART",	content: STRadiostart	};
export type ScreenOfSelectList	= BaseScreen & { stId: "SELECTLIST",	content: STSelectList	};
export type ScreenOfDate		= BaseScreen & { stId: "DATE",			content: STDate			};
export type ScreenOfFlight		= BaseScreen & { stId: "FLIGHT",		content: STFlight		};
export type ScreenOfHotel		= BaseScreen & { stId: "HOTEL",			content: STHotel		};
export type ScreenOfCounterList	= BaseScreen & { stId: "COUNTERLIST",	content: STCounterList	};
export type ScreenOfSliderList	= BaseScreen & { stId: "SLIDERLIST",	content: STSliderList	};
export type ScreenOfBudget		= BaseScreen & { stId: "BUDGET",		content: STBudget		};
export type ScreenOfContact		= BaseScreen & { stId: "CONTACT",		content: STContact		};
export type ScreenOfText		= BaseScreen & { stId: "TEXT",			content: STText			};
export type ScreenOfDecision	= BaseScreen & { stId: "DECISION",		content: STDecision		};

export type BaseScreen	= Readonly<{
	id:						ScreenId,
	questionnaireScreenId:	QuestionnaireScreenId,
	description:			string,
	conditions:				ReadonlyArray<Condition>,
}>;

//-----------------------------------------------------------------------------

// TODO fbtype this is not as useful as it seems
// export type AnswerForScreen<S extends Screen>	= Answer & { stId: S["stId"] };
export type AnswerForScreen<S extends Screen>	= AnswerOf<ScreenKindOf<S>>;

//-----------------------------------------------------------------------------

export type Answer	=
	AnswerOfRadiostart	|
	AnswerOfSelectList	|
	AnswerOfDate		|
	AnswerOfFlight		|
	AnswerOfHotel		|
	AnswerOfCounterList	|
	AnswerOfSliderList	|
	AnswerOfBudget		|
	AnswerOfContact		|
	AnswerOfText		|
	AnswerOfDecision;

export const Answer	= {
	isOf:	<I extends AnswerKind>(stId:I) => (self:Answer):self is AnswerOf<I> => self.stId === stId,
};

export type AnswerKind	= Answer["stId"];
export type AnswerType	= Answer["content"];

export type AnswerKindOf<I extends Answer>		= I["stId"];
export type AnswerOf<I extends Answer["stId"]>	= Extract<Answer, { stId:I }>;

export type AnswerOfRadiostart	= BaseAnswer & { stId: "RADIOSTART",	content: ARadiostart	};
export type AnswerOfSelectList	= BaseAnswer & { stId: "SELECTLIST",	content: ASelectList	};
export type AnswerOfDate		= BaseAnswer & { stId: "DATE",			content: ADate			};
export type AnswerOfFlight		= BaseAnswer & { stId: "FLIGHT",		content: AFlight		};
export type AnswerOfHotel		= BaseAnswer & { stId: "HOTEL",			content: AHotel			};
export type AnswerOfCounterList	= BaseAnswer & { stId: "COUNTERLIST",	content: ACounterList	};
export type AnswerOfSliderList	= BaseAnswer & { stId: "SLIDERLIST",	content: ASliderList	};
export type AnswerOfBudget		= BaseAnswer & { stId: "BUDGET",		content: ABudget		};
export type AnswerOfContact		= BaseAnswer & { stId: "CONTACT",		content: AContact		};
export type AnswerOfText		= BaseAnswer & { stId: "TEXT",			content: AText			};
export type AnswerOfDecision	= BaseAnswer & { stId: "DECISION",		content: ADecision		};

export type BaseAnswer	= Readonly<{
	screenId:				ScreenId,
	questionnaireScreenId:	QuestionnaireScreenId,
	screenDescription?:		string,
	show:					boolean,
}>;

//-----------------------------------------------------------------------------

export type ConditionId		= Branded<number, "ConditionId">;
export const ConditionId	= Branded.brand<ConditionId>;

export type Condition = Readonly<{
	conditionId:					ConditionId,
	questionnaireScreenId:			QuestionnaireScreenId,
	mustBe:							boolean,
	sourceQuestionnaireScreenId:	QuestionnaireScreenId,
	expectedRadioItemId:			RadioItemId|null,
}>;

//-----------------------------------------------------------------------------

export const AnswersRequest	=
	JS.objectOf({
		lang:	LanguageCode.json,
		// TODO validate
		result:	it => Validated.valid(it as Result),
	});
export type AnswersRequest	= JS.Output<typeof AnswersRequest>;

export type Result = Readonly<{
	questionnaireId:			QuestionnaireId,
	questionnaireName:			string,
	questionnaireDescription:	string,
	answers:					ReadonlyArray<Answer>,
	territoryId:				TerritoryId|null,
	regionId:					RegionId|null,
	routeId:					ExampleRouteId|null,
}>;

export type AnswersResponse = Readonly<{
	return: "ok"|"error",
}>;

// ===============================================================================
// Screentypes
// ===============================================================================

export type STRadiostart = Readonly<{
	intro:		string,
	radioGroup:	RadioGroup,
}>;

export type STSelectList = Readonly<{
	title:			string,
	modalTitle:		string,
	list:			ReadonlyArray<ListItem>,
	keyList:		string,
	mailListKey:	string,
}>;

export type STDate = Readonly<{
	counter1:		CountItem,
	counter2:		CountItem,
	// TODO kv restrict
	keyDate:		string,
	dateTitle:		string,
	mailDateKey:	string,
}>;

export type STFlight = Readonly<{
	icon:				string,
	radioGroup:			RadioGroup,
	modalTitle:			string,
	mailModalTitle:		string,
	// TODO kv restrict
	keyCountry:			string,
	keyPostcode:		string,
	mailCountryTitle:	string,
	mailPostcodeTitle:	string,
	labelCountry:		string,
	labelPostcode:		string,
	autocompleteList:	ReadonlyArray<string>,
}>;

export type STHotel = Readonly<{
	title:		string,
	counter:	CountItem,
	radioGroup:	RadioGroup,
}>;

export type STCounterList = Readonly<{
	list:	ReadonlyArray<CountItem>,
}>;

export type STSliderList = Readonly<{
	title:		string,
	sliders:	ReadonlyArray<SliderItem>,
}>;

export type STBudget	= Readonly<{
	title1:				string,
	title2:				string,
	note:				string,
	dayDefault:			Euro,
	dayAverages:		DayAverages,
	mailBudgetKey:		string,
	impressionTitle:	string,
	impressionSets:		ImpressionList,
}>;

export type STContact = Readonly<{
	keyComment:				string,
	keyMail:				string,
	keyCheckbox:			string,
	title:					string,
	introTextarea:			string,
	placeholderTextarea:	string,
	mailContactKey:			string,
	labelMail:				string,
	labelCheckbox1:			string,
	labelCheckbox2:			string,
	labelLink:				string,
	labelLinkTitle:			string,
	requiredMsgMail:		string,
	requiredMsgCheckbox:	string,
}>;

export type STText = Readonly<{
	title:		string,
	icon:		string,
	paragraphs:	ReadonlyArray<string>,
	greeting1:	string,
	greeting2:	string,
}>;

export type STDecision = Readonly<{
	radioGroup:RadioGroup,
}>;

// ===============================================================================
// Answers
// ===============================================================================

export type RadioItemId		= Branded<number, "RadioItemId">;
export const RadioItemId	= Branded.brand<RadioItemId>;

export type ARadiostart = Readonly<{
	radioItemId:	RadioItemId | null,
	keyRadio:		string,
	valueRadio:		string | null,
	valueDefault:	string | null,
	mailRadioKey:	string,
	mailRadioValue:	string,
}>;

export type ASelectList = Readonly<{
	selected:			ReadonlyArray<AListItem>,
	selectedDefault:	ReadonlyArray<AListItem>,
	// TODO kv restrict
	keyList:			string,
	mailListKey:		string,
}>;

export type ADate = Readonly<{
	counter1:		ACountItem,
	counter2:		ACountItem,
	date:			string,
	dateDefault:	string,
	// TODO kv
	keyDate:		string,
	mailDateKey:	string,
}>;

export type AFlight = Readonly<{
	radioItemId:		RadioItemId | null,
	keyRadio:			string,
	valueRadio:			string | null,
	valueDefault:		string | null,
	countryValue:		string,
	valuePostcode:		string,
	// TODO kv restrict
	keyCountry:			string,
	keyPostcode:		string,
	mailModalTitle:		string,
	mailCountryTitle:	string,
	mailPostcodeTitle:	string,
	mailRadioKey:		string,
	mailRadioValue:		string,
}>;

export type AHotel = Readonly<{
	counter:		ACountItem,
	radioItemId:	RadioItemId | null,
	keyRadio:		string,
	valueRadio:		string | null,
	valueDefault:	string | null,
	mailRadioKey:	string,
	mailRadioValue:	string,
 }>;

export type ACounterList = Readonly<{
	counterValues:	ReadonlyArray<ACountItem>,
}>;

export type ASliderList = Readonly<{
	sliderValues:	ReadonlyArray<ASlider>,
}>;

export type ABudget = Readonly<{
	budget:			Percent,
	budgetDefault:	Percent,
	dayAverages:	DayAverages,
	mailBudgetKey:	string,
}>;

export type AContact = Readonly<{
	keyComment:		string,
	keyMail:		string,
	keyCheckbox:	string,
	returnTextarea:	string,
	returnDefault:	string,
	returnMail:		string,
	returnCheckbox:	boolean,
	mailContactKey:	string,
}>;

export type AText = Readonly<Record<string, never>>;

export type ADecision = Readonly<{
	radioItemId:	RadioItemId | null,
	keyRadio:		string,
	valueRadio:		string | null,
	valueDefault:	string | null,
	mailRadioKey:	string,
	mailRadioValue:	string,
}>;

// ===============================================================================
// Components and their Answer types
// ===============================================================================

export type ListItem = Readonly<{
	id:			ListItemId,
	valueLi:	string,
	label:		string,
	icon:		string,
	children:	ReadonlyArray<SubListItem>,
}>;

export type AListItem = Readonly<{
	id:			ListItemId,
	valueLi:	string,
	mailValue:	string,
	children:	ReadonlyArray<ASubListItem>,
}>;

export type SubListItem = Readonly<{
	id:			SubListItemId,
	valueLi:	string,
	label:		string,
}>;

export type ASubListItem = Readonly<{
	id:				SubListItemId,
	valueLi:		string,
	mailValue:		string,
}>;

export type SliderItem = Readonly<{
	iconMin:	string,
	iconMax:	string,
	labelMin:	string,
	labelMax:	string,
	keySlider:	string,
	mailKey:	string,
}>;

export type ASlider = Readonly<{
	keySlider:		string,
	sliderValue:	number,
	sliderDefault:	number,
	mailKey:		string,
	mailValue:		string,
}>;

export type CountItem = Readonly<{
	title:			string,
	icon:			string,
	subTitle?:		string,
	keyCounter:		string,
	mailKey:		string,
	counterDefault:	number,
	min:			number,
	max:			number,
}>;

export type ACountItem = Readonly<{
	keyCounter:		string,
	counterDefault:	number,
	counterValue:	number,
	mailKey:		string,
}>;

export type RadioLayout	= "icon" | "numbers" | "text";

export type RadioGroup = Readonly<{
	title:		string,
	// TODO kv make this a special string
	keyRadio:	string,
	layout:		RadioLayout,
	mailKey:	string,
	items:		ReadonlyArray<RadioItem>,
}>;

export type RadioItem = Readonly<{
	icon:			string|null,
	label:			string,
	valueRadio:		string,
	radioItemId:	RadioItemId,
}>;

//-----------------------------------------------------------------------------

export const SliderValues	= {
	minimum:	0,
	neutral:	50,
	maximum:	100,
};

//-----------------------------------------------------------------------------

export type ImpressionList = readonly [ ImpressionSet, ImpressionSet, ImpressionSet, ImpressionSet ];

export namespace ImpressionList {
	// prevents an index of of bounds access for 100%
	export const forBudget	= (self:ImpressionList, budget:number):ImpressionSet	=>
		self[Math.min(Math.floor(budget / 25), 3)];
}

export type ImpressionSet	= ReadonlyArray<ImpressionItem>;

export type ImpressionItem = Readonly<{
	image:	string,
	text:	string,
}>;

//-----------------------------------------------------------------------------

// NOTE this has to have exactly one element more than ImpressionList
// this is a partially defined function from values in EUR to percentages in 0..100
// the values are positive and guaranteed to be in ascending order
export type DayAverages = [
	// 0%
	Euro,
	// 25%
	Euro,
	// 50%
	Euro,
	// 75%
	Euro,
	// 100%
	Euro,
];

const budgetPercentToEuro = (percentVal:Percent, dayAvg:DayAverages):Euro =>
	Euro.brand(
		// NOTE this is a false positive
		// eslint-disable-next-line no-implicit-coercion
		percentVal < 25 ?	dayAvg[0] + (dayAvg[1] - dayAvg[0]) * (percentVal - 0) / 25		:
		percentVal < 50 ?	dayAvg[1] + (dayAvg[2] - dayAvg[1]) * (percentVal - 25) / 25	:
		percentVal < 75 ?	dayAvg[2] + (dayAvg[3] - dayAvg[2]) * (percentVal - 50) / 25	:
							dayAvg[3] + (dayAvg[4] - dayAvg[3]) * (percentVal - 75) / 25
	);

const budgetEuroToPercent = (realVal:Euro, dayAvg:DayAverages):Percent =>
	Percent.brand(
		realVal < dayAvg[1] ?	0 + 25 * (realVal - dayAvg[0]) / (dayAvg[1] - dayAvg[0])	:
		realVal < dayAvg[2] ?	25 + 25 * (realVal - dayAvg[1]) / (dayAvg[2] - dayAvg[1])	:
		realVal < dayAvg[3] ?	50 + 25 * (realVal - dayAvg[2]) / (dayAvg[3] - dayAvg[2])	:
								75 + 25 * (realVal - dayAvg[3]) / (dayAvg[4] - dayAvg[3])
	);

export const DayAverages	= {
	budgetPercentToEuro,
	budgetEuroToPercent,
};

//-----------------------------------------------------------------------------

export type ExampleRoute = Readonly<{
	id:					ExampleRouteId,
	regionId:			RegionId,
	territoryId:		TerritoryId,
	name:				string,
	catchword:			string,
	descriptionShort:	string,
	descriptionLong:	string,
	travelDays:			number,
	hotelsFound:		boolean,
}>;
